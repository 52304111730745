<!-- Localized -->
<template>
  <div class="rounded-lg p-3 bg-white text-base-content">
    <table class="permissions-table text-xs">
      <thead>
        <th></th>
        <th
          v-for="(header, i) in headers"
          :key="i"
        >
          <div class="flex w-dull items-center justify-center">
            <component :is="header" class="fill-current" />
          </div>
        </th>
      </thead>
      <tbody>
        <tr
          v-for="role in roles"
          :key="role.id"
        >
          <td>
            <div class="flex items-center gap-2">
              <Checkbox
                v-model="role.select_all"
                @input="selectAllPermissions(role)"
                :disabled="shouldRenderContent"
              />
              <span class="font-semibold">{{ role.title }}</span>
            </div>
          </td>
          <td>
            <Checkbox
              :disabled="role.role_write || role.role_mandatory || shouldRenderContent"
              @input="permissionChange(role)"
              v-model="role.role_read"
            />
          </td>
          <td>
            <Checkbox
              :disabled="role.role_mandatory || shouldRenderContent"
              @input="permissionChange(role)"
              v-model="role.role_write"
            />
          </td>
          <td>
            <Checkbox
              :disabled="role.role_write || role.role_mandatory || role.role_read || shouldRenderContent"
              @input="permissionChange(role)"
              v-model="role.role_visible"
            />
          </td>
          <td>
            <Checkbox
              @input="permissionChange(role)"
              v-model="role.role_mandatory"
              :disabled="shouldRenderContent"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Checkbox from "@/components/checkbox-alt";
import ReadIcon from "@/assets/icons/roles/read.svg";
import WriteIcon from "@/assets/icons/roles/write.svg";
import VisibleIcon from "@/assets/icons/roles/visible.svg";
import MandatoryIcon from "@/assets/icons/roles/mandatory.svg";
import { fetchAllRoles  } from "@/modules/field-editor/services.js";
import { cloneDeep } from "lodash";

export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    queryData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Checkbox,
  },
  data() {
    return {
      headers: [
        ReadIcon,
        WriteIcon,
        VisibleIcon,
        MandatoryIcon,
      ],
      allRoles: [],
    }
  },
  computed: {
    roles() {
      let roles_order = {
        'Analyst': 0,
        'Subject': 1,
        'Client': 2,
        'Referee': 3,
      };
      let allRoles = cloneDeep(this.field.roles || this.field.permissions);
      allRoles = allRoles.map((role) => ({
        ...role, 
        ...this.allRoles.find((rl) => rl.id === role.role_id)
      }));
      const role_list = allRoles.sort((a, b) => {
          return roles_order[a.title] > roles_order[b.title] ? 1: -1;
      })

      return role_list || [];
    },
    shouldRenderContent() {
    return !this.queryData || this.queryData.action == 'view';
  }
  },
  methods: {
    permissionChange(role) {
      this.$emit("permissionChange", role);
      this.$emit('change');
    },
    selectAllPermissions(role) {
      this.$emit("selectAllPermissions", role);
      this.$emit('change');
    },
    fetchAllRoles,
  },
  async mounted() {
    this.allRoles = await this.fetchAllRoles();
    console.log("----->this.shouldRenderContent",this.shouldRenderContent)
    console.log("----->this.queryData",this.queryData)
  }
}
</script>

<style lang="scss" scoped>
.permissions-table {
  td, th {
    @apply p-1.5;
    text-align: center; 
    vertical-align: middle;
  }
}
</style>